<template>
  <div class="add-on-solutions">
    <div class="header">
      <h1>{{ $t('message.addOnSolutions') }}</h1>
      <span>{{ $t('message.toHelpOptimizeYourFleet') }}</span>
    </div>
    <div class="divider" />
    <div class="solutions">
      <div @click="$router.push('/8/detail/ivc')" class="solution">
        <div class="info-wrap">
          <img src="@/assets/addonivc.svg?external" />
          <h3>{{ $t('message.inVehicleCoaching') }}</h3>
          <b>{{ $i18n.locale === "fr-ca" ? "6,99$" : "$6.99" }} {{ $t('message.vehicle') }} / {{ $t('message.month') }}</b>
        </div>
        <div class="button-wrap">
          <button id="ivc-btn" class="action-button major">{{ $t('message.learnMore') }}</button>
        </div>
      </div>
      <div @click="$router.push('/8/detail/adapter')" class="solution">
        <div class="info-wrap">
          <img src="@/assets/addonmixedfleet.svg?external" />
          <h3>{{ $t('message.fleetAdapter') }}</h3>
          <b>{{ $i18n.locale === "fr-ca" ? "85,00$" : "$85.00" }} {{ $t('message.device') }}</b>
        </div>
        <div class="button-wrap">
          <button class="action-button major">{{ $t('message.learnMore') }}</button>
        </div>
      </div>
      <div @click="$router.push('/8/detail/evgo')" class="solution">
        <div class="info-wrap">
          <div class="new">
            <img src="@/assets/addonevgo.svg?external" />
            <b>{{ $t('message.new') }}!</b>
          </div>
          <h3>{{ $t('message.plugAndCharge') }}</h3>
          <b>{{ $t('message.withEVgo') }}</b>
        </div>
        <div class="button-wrap">
          <button id="evgo-btn" class="action-button major">{{ $t('message.learnMore') }}</button>
        </div>
      </div>
      <div @click="$router.push('/8/detail/adas')" class="solution">
        <div class="info-wrap">
          <div class="new">
            <img src="@/assets/addonadas.svg?external" />
            <b>{{ $t('message.new') }}!</b>
          </div>
          <h3>{{ $t('message.adasInsights') }}</h3>
        </div>
        <div class="button-wrap">
          <button class="action-button major">{{ $t('message.learnMore') }}</button>
        </div>
      </div>
      <div @click="$router.push('/8/detail/camera')" class="solution">
        <div class="info-wrap">
          <img src="@/assets/lytx-camera.svg?external" style="height: 32px" />
          <h3>Insights DualCam</h3>
          <b>$450.00 per Device + $40 Device / Month</b>
        </div>
        <div class="button-wrap">
          <button class="action-button major">{{ $t('message.learnMore') }}</button>
        </div>
      </div>
    </div>
    <GuidedTourWaypoint :id="18" target="ivc-btn" :xOffset="-10" :yOffset="30" />
    <GuidedTourWaypoint :id="19" target="evgo-btn" :xOffset="-10" :yOffset="30">
      Add Plug and Charge to your plan
    </GuidedTourWaypoint>
  </div>
</template>

<script>
import GuidedTourWaypoint from "../../components/demo/GuidedTourWaypoint.vue";

export default {
  components: {
    GuidedTourWaypoint,
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #f4f3f7;
  display: flex;
  flex-direction: column;
  text-align: center;
  span {
    color: #76777a;
  }
}
.solutions {
  margin: 0 10%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  gap: 20px;
  .solution {
    width: 100%;
    height: 212px;
    border: 1px solid #001c3829;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #69cdff1f;
    }
    .info-wrap {
      width: 100%;
      height: calc(100% - 60px);
      display: grid;
      align-items: center;
      align-content: center;
      justify-content: center;
      justify-items: center;
      grid-template-rows: 60px 40px 16px;
      h3 {
        font-size: 16px;
        margin: 0 0 8px 0;
      }
      b {
        font-size: 12px;
        color: #606164;
      }
      img {
        height: 40px;
      }
      .new {
        display: grid;
        grid-template-rows: 40px auto;
        justify-items: center;
        b {
          font-size: 15px;
          color: #05b5e4;
          margin-top: -4px;
        }
      }
    }
    .button-wrap {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #001c3829;
    }
  }
}
.divider {
  background: linear-gradient(to bottom, #f4f3f7, #ffffff 100%);
  width: 100%;
  height: 50px;
}

.action-button {
  // margin-left: 8px;
  height: 30px;
  min-width: 100px;
  padding: 0 8px;
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.action-button svg {
  width: 18px;
  height: 18px;
  fill: var(--blue);
  // margin-right: 8px;
}

.action-button.major {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  // margin-left: 16px;
}
</style>