<template>
  <div>
    <Modal x v-show="$store.state.showModal">
      <span slot="header">{{ modalHeading }}</span>
      <div v-if="!$store.state.showBillingModal" slot="body">
        <section class="content">
          <p>{{ $t("message.autoPay") }}</p>
          <button class="primary">
            <svg
              id="gfs:icon1/i-expense"
              class="credit-card icon"
              viewBox="0 0 24 24"
            >
              <path
                d="M22.4 5v11a3 3 0 01-3 3h-14a3 3 0 01-3-3V5h20zm-1.2 5.4H3.599L3.6 16a1.8 1.8 0 001.652 1.794l.148.006h14a1.8 1.8 0 001.794-1.652L21.2 16v-5.6zm-10.692 3.1v1.2h-4.8v-1.2h4.8zM21.2 6.2H3.6L3.599 8H21.2V6.2z"
              ></path>
            </svg>
            {{ $t("message.creditCard") }}
          </button>
          <p class="or">-{{ $t("message.or") }}-</p>
          <p>{{ $t("message.payUponReceipt") }}</p>
          <button class="secondary">
            <svg
              class="envelope icon"
              id="gfs:icon1/i-envelope"
              viewBox="0 0 24 24"
            >
              <path
                d="M21.6 5.4v10.7a2.6 2.6 0 01-2.6 2.6H5a2.6 2.6 0 01-2.6-2.6V5.4h19.2zm-1.935 4.772l-.18.088L12 13.646 4.516 10.26a3.596 3.596 0 01-.916-.597V16.1a1.4 1.4 0 001.265 1.394l.135.006h14a1.4 1.4 0 001.4-1.4l.001-6.437c-.22.198-.467.37-.736.51zm.735-3.573H3.6v.38a2.4 2.4 0 001.253 2.11l.158.077L12 12.33l6.99-3.163a2.4 2.4 0 001.404-2.01l.006-.176v-.381z"
              ></path>
            </svg>
            {{ $t("message.invoiceMe") }}
          </button>
        </section>
      </div>
      <div v-else slot="body">
        <MiniDataTable :data="$store.state.billingHistory" />
      </div>
    </Modal>
    <h1>{{ $t("message.billingAndSubscriptions") }}</h1>
    <div class="gradient-divider"></div>
    <main class="grid">
      <section>
        <header>
          <h2 id="subs-header">{{ $t("message.subscriptions") }}</h2>
        </header>
        <div class="sub-section border" id="plus-plan-widget">
          <div class="sub-section-header flex align-center spaced-between" style="height: 15px; margin-bottom: 10px;">
            <p class="small-text">{{ $t("message.onStarVehicleInsights") }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" style="fill: var(--blue); width: 22px; margin-top: 4px;" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
            </svg>
          </div>
          <div class="flex spaced-between">
            <img class="logo" src="@/assets/logo_circle.png" alt="" />
            <div class="plan-grid">
              <b v-if="inEssentialsMode">Business Essentials Plan</b>
              <b v-else>Plus Plan</b>
              <b style="color: #76777a">Since Sept. 22, 2023</b>
              <div class="num-vehicles">
                <svg id="gfs:icon1/i-vehicle" viewBox="0 0 24 24">
                  <path d="M21.167 8.875h-1.359a1.042 1.042 0 0 1-.966-.667l-1.667-4.333H6.833L5.167 8.217c-.157.396-.54.657-.967.658H2.833v1.25h1.342l-1.133 1.6v8.4h4.583v-3.333h8.75v3.333h4.583v-8.4l-1.133-1.6h1.342v-1.25zM7.683 5.125h8.634l1.358 3.542c.027.077.06.153.1.225H6.225c.039-.073.072-.148.1-.225l1.358-3.542zm-1.308 13.75H4.292v-2.342c.322.167.679.255 1.041.259h1.042v2.083zm11.25 0v-2.083h1.042c.362-.004.72-.092 1.041-.259v2.342h-2.083zm1.042-3.333H5.333A1.042 1.042 0 0 1 4.292 14.5v-2.375l1.1-1.558c.193-.27.501-.434.833-.442h11.533c.332.008.64.172.834.442l1.1 1.558V14.5c0 .569-.457 1.033-1.025 1.042zM5.333 12.208h3.334v1.25H5.333v-1.25zm10 0h3.334v1.25h-3.334v-1.25z"></path>
                </svg>
                <b>1</b>
              </div>
            </div>
            <div class="price-grid">
              <b>$0.00</b>
              <span>Plus Tax</span>
              <span>Monthly Estimate</span>
            </div>
          </div>
        </div>
        <div v-if="!$store.state.inDealerMode && !inEssentialsMode" class="border sub-section">
          <div class="sub-section-header flex align-center spaced-between" style="height: 15px; margin-bottom: 10px;">
            <p class="small-text">{{ $t("message.addonServices") }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" style="fill: var(--blue); width: 22px; margin-top: 4px;" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
            </svg>
          </div>
          <div class="flex spaced-between">
            <div class="border circle coaching-icon-container">
              <svg
                id="gfs:icon1/ivc-lg"
                viewBox="0 0 24 24"
                class="coaching-icon medium-icon"
              >
                <path
                  fill="#000"
                  d="M10.54 4c.38.1.66.2.82.26l.17.08a8.24 8.24 0 011.92 1.34 6.67 6.67 0 011.66 2.35c.1.25.17.51.24.8l.12.59.17 1 .04.2.07.14c.08.16.21.4.39.7l.35.6.85 1.4a.4.4 0 01-.26.61l-.08.01h-1.22v2.38c-.03.3-.18.57-.4.77l-.1.07a.38.38 0 01-.15.06l-.08.01h-1.77c-.32 0-.47.09-.56.23a.47.47 0 00-.06.17v.1l-.33 2.71-.8-.1.33-2.65c0-.18.04-.4.17-.63.2-.36.57-.58 1.07-.62h1.8l.03-.05.04-.06.01-.05v-2.74c0-.2.14-.36.32-.4h.98l-.29-.5-.24-.4c-.42-.7-.7-1.2-.82-1.5l-.05-.22a7.14 7.14 0 01-.06-.26l-.19-1.1-.02-.07a5.83 5.83 0 00-.24-.9 5.86 5.86 0 00-1.46-2.07 7.5 7.5 0 00-1.8-1.24L11.05 5a6.48 6.48 0 00-4.7-.07l-.33.13c-2.22 1.02-3.3 3.08-3.45 5.77v.52a8.61 8.61 0 001.2 3.48l.3.52c.26.47.77 1.33.89 1.53.18.33.33.68.45 1.07.19.58.31 1.2.38 1.82l.03.34.03.4v.09h-.8v-.18a9.56 9.56 0 00-.4-2.22 5.24 5.24 0 00-.4-.93l-1-1.73c-.9-1.58-1.39-2.86-1.48-4.13a5.22 5.22 0 010-.63c.15-2.85 1.31-5.12 3.66-6.32l.32-.15a7.17 7.17 0 014.79-.32z"
                ></path>
                <path
                  fill="#1D6091"
                  d="M20.55 12.18a6.1 6.1 0 01.23 8.36l-.18.2-.57-.57a5.3 5.3 0 00.12-7.24l-.17-.19.57-.56zm-.92.88c1.81 1.8 1.9 4.7.23 6.6l-.16.18-.58-.56a4.03 4.03 0 00.1-5.5l-.15-.15.56-.57zm-.9.88a3.57 3.57 0 01.21 4.85l-.13.15-.58-.55c1-1.05 1-2.68.06-3.74l-.13-.14.57-.57z"
                ></path>
              </svg>
            </div>
            <div class="plan-grid">
              <b>In-Vehicle Coaching</b>
              <b style="color: #76777a">Since Sept. 22, 2023</b>
              <div class="num-vehicles">
                <svg id="gfs:icon1/i-vehicle" viewBox="0 0 24 24">
                  <path d="M21.167 8.875h-1.359a1.042 1.042 0 0 1-.966-.667l-1.667-4.333H6.833L5.167 8.217c-.157.396-.54.657-.967.658H2.833v1.25h1.342l-1.133 1.6v8.4h4.583v-3.333h8.75v3.333h4.583v-8.4l-1.133-1.6h1.342v-1.25zM7.683 5.125h8.634l1.358 3.542c.027.077.06.153.1.225H6.225c.039-.073.072-.148.1-.225l1.358-3.542zm-1.308 13.75H4.292v-2.342c.322.167.679.255 1.041.259h1.042v2.083zm11.25 0v-2.083h1.042c.362-.004.72-.092 1.041-.259v2.342h-2.083zm1.042-3.333H5.333A1.042 1.042 0 0 1 4.292 14.5v-2.375l1.1-1.558c.193-.27.501-.434.833-.442h11.533c.332.008.64.172.834.442l1.1 1.558V14.5c0 .569-.457 1.033-1.025 1.042zM5.333 12.208h3.334v1.25H5.333v-1.25zm10 0h3.334v1.25h-3.334v-1.25z"></path>
                </svg>
                <b>1</b>
              </div>
            </div>
            <div class="price-grid">
              <b>$0.00</b>
              <span>Plus Tax</span>
              <span>Monthly Estimate</span>
            </div>
          </div>
        </div>
        <div v-if="!$store.state.inDealerMode && !inEssentialsMode" class="border sub-section">
          <div class="sub-section-header flex align-center spaced-between" style="height: 15px; margin-bottom: 10px;">
            <p class="small-text">{{ $t("message.addonServices") }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" style="fill: var(--blue); width: 22px; margin-top: 4px;" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
            </svg>
          </div>
          <div class="flex spaced-between">
            <div class="border circle coaching-icon-container">
              <svg style="height: 12px; margin-top: 6px;" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.03857 1.23431C1.75852 1.23431 1.50925 1.4441 1.50925 1.72717V6.68819C1.50925 6.97126 1.75852 7.18105 2.03857 7.18105H8.73649C9.01655 7.18105 9.26582 6.97126 9.26582 6.68819V1.72717C9.26582 1.4441 9.01655 1.23431 8.73649 1.23431H2.03857ZM10.1917 2.61136V1.72717C10.1917 1.01405 9.55233 0.416016 8.73649 0.416016H2.03857C1.22273 0.416016 0.583328 1.01405 0.583328 1.72717V6.68819C0.583328 7.40131 1.22273 7.99935 2.03857 7.99935H8.73649C9.55233 7.99935 10.1917 7.40131 10.1917 6.68819V5.804L13.4167 7.38251V1.03285L10.1917 2.61136ZM10.1917 3.54678V4.86858L12.4907 5.99388V2.42149L10.1917 3.54678Z"
                  fill="#76777a"
                />
              </svg>
            </div>
            <div class="plan-grid">
              <b>Insights DualCam</b>
              <b style="color: #76777a">Since Sept. 22, 2023</b>
              <div class="num-vehicles">
                <img src="@/assets/Video.svg?external" />
                <b>1</b>
              </div>
            </div>
            <div class="price-grid">
              <b>$0.00</b>
              <span>Plus Tax</span>
              <span>Monthly Estimate</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          class="sub-section"
          id="estimate-container"
        >
          <div class="value-table">
            <span class="medium-text">
              {{ $t("message.monthlyEstimate") }}
            </span>
            <span class="bold left-align">
              {{
                $i18n.locale === "es"
                  ? "$0.00"
                  : $i18n.locale === "fr-ca"
                  ? "0,00 $"
                  : "$0.00"
              }}
              {{ $t("message.plusTax") }}
            </span>

            <span class="medium-text">{{ $t("message.nextChange") }}</span>
            <span class="bold left-align">12/12/2020</span>

            <div class="line" />

            <span class="medium-text">{{ $t("message.accountBalance") }}</span>
            <span class="bold left-align">$0.00</span>
          </div>
          <div class="flex" style="gap: 8px;">
            <button class="action-button">
              {{ $t("message.cancelSubscription") }}
            </button>
            <!-- <button class="action-button">
              {{ $t("message.selectPaymentType") }}
            </button> -->
          </div>
        </div>
      </section>
      <section class="divider">
        <header>
          <h2>{{ $t("message.billingInfo") }}</h2>
        </header>
        <div class="border sub-section" v-if="!$store.state.inDealerMode">
          <div class="sub-section-header flex spaced-between align-center">
            <h3>{{ $t("message.paymentInformation") }}</h3>
            <button
              class="bg-white color-blue bold"
              style="padding: 0;"
              @click="$store.commit('toggleModal')"
            >
            <svg id="gfs:icon1/edit" style="width: 18px; fill: var(--blue);" viewBox="0 0 24 24">
              <path d="M16.995 3L5.007 14.997 3 21l6.003-1.998L21 7.005 16.995 3zM5.241 18.759l.9-2.637 1.8 1.8-2.7.837zm3.762-1.8l-1.998-1.962 7.992-7.992 1.998 1.998-7.992 7.956zm6.993-10.953l.999-.999 1.998 1.998-.999.999-1.998-1.998z"></path>
            </svg>
            </button>
          </div>
          <div class="billing-details payment-info">
            <button class="secondary">
              <svg
                class="envelope icon"
                id="gfs:icon1/i-envelope"
                viewBox="0 0 24 24"
              >
                <path
                  d="M21.6 5.4v10.7a2.6 2.6 0 01-2.6 2.6H5a2.6 2.6 0 01-2.6-2.6V5.4h19.2zm-1.935 4.772l-.18.088L12 13.646 4.516 10.26a3.596 3.596 0 01-.916-.597V16.1a1.4 1.4 0 001.265 1.394l.135.006h14a1.4 1.4 0 001.4-1.4l.001-6.437c-.22.198-.467.37-.736.51zm.735-3.573H3.6v.38a2.4 2.4 0 001.253 2.11l.158.077L12 12.33l6.99-3.163a2.4 2.4 0 001.404-2.01l.006-.176v-.381z"
                ></path>
              </svg>
              {{ $t("message.invoiceMe") }}
            </button>
            <b>How to send Payment?</b>
          </div>
        </div>
        <div class="sub-section border">
          <div>
            <div class="flex spaced-between align-center sub-section-header">
              <h3>{{ $t("message.billingAddress") }}</h3>
              <button style="padding: 0;" class="bg-white color-blue bold">
                <svg id="gfs:icon1/edit" style="width: 18px; fill: var(--blue);" viewBox="0 0 24 24">
                  <path d="M16.995 3L5.007 14.997 3 21l6.003-1.998L21 7.005 16.995 3zM5.241 18.759l.9-2.637 1.8 1.8-2.7.837zm3.762-1.8l-1.998-1.962 7.992-7.992 1.998 1.998-7.992 7.956zm6.993-10.953l.999-.999 1.998 1.998-.999.999-1.998-1.998z"></path>
                </svg>
              </button>
            </div>
            <div class="billing-details">
              <!-- houses icon -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="70"
                height="58"
                viewBox="0 0 70 58"
              >
                <defs>
                  <path
                    id="snik31u79a"
                    d="M0 0.215L69.671 0.215 69.671 1.551 0 1.551z"
                  />
                  <path
                    id="isb5g0fi7c"
                    d="M0 56.335L69.671 56.335 69.671 0.144 0 0.144z"
                  />
                  <path
                    id="qr7asio6te"
                    d="M0 56.335L69.671 56.335 69.671 0.144 0 0.144z"
                  />
                  <path
                    id="oqmu1asy1g"
                    d="M0 56.335L69.671 56.335 69.671 0.144 0 0.144z"
                  />
                  <path
                    id="3fojk8bzhi"
                    d="M0 56.335L69.671 56.335 69.671 0.144 0 0.144z"
                  />
                  <path
                    id="qs9a4pnylk"
                    d="M0 56.335L69.671 56.335 69.671 0.144 0 0.144z"
                  />
                </defs>
                <g fill="none" fill-rule="evenodd">
                  <path
                    fill="#DAE0E8"
                    fill-rule="nonzero"
                    d="M60.911 56h5.681V32h-5.68v24zM39.991 0v12.589h-7.124V4.375H14.65v11.54l5.76-4.87 11.84 10.007V56h5.205V21.052l11.838-10.007 5.937 5.02V0H39.991z"
                  />
                  <g transform="translate(.042 .8)">
                    <g transform="translate(0 54.785)">
                      <mask id="4e69c4xxrb" fill="#fff">
                        <use xlink:href="#snik31u79a" />
                      </mask>
                      <path
                        fill="#171C26"
                        fill-rule="nonzero"
                        d="M0 .883C0 .514.306.215.683.215h68.306c.378 0 .683.3.683.668 0 .178-.073.349-.2.474-.13.125-.303.195-.483.194H.683c-.18 0-.354-.07-.482-.194C.073 1.23 0 1.06 0 .883"
                        mask="url(#4e69c4xxrb)"
                      />
                    </g>
                    <path
                      fill="#000"
                      fill-rule="nonzero"
                      d="M34.764 23.865c-.165 0-.324-.058-.449-.164L20.57 11.981 6.823 23.701c-.284.243-.714.214-.963-.064-.12-.133-.18-.308-.167-.485.013-.177.096-.341.233-.457L20.12 10.592c.258-.22.64-.22.898 0l14.194 12.103c.284.242.315.664.066.942-.13.146-.318.229-.515.228"
                    />
                    <path
                      fill="#C1C8D6"
                      fill-rule="nonzero"
                      d="M23.308 19.557c0 1.478-1.226 2.676-2.74 2.676-1.51 0-2.736-1.198-2.736-2.676 0-1.478 1.225-2.677 2.738-2.677 1.511 0 2.738 1.199 2.738 2.677"
                    />
                    <mask id="h7s24hpjyd" fill="#fff">
                      <use xlink:href="#isb5g0fi7c" />
                    </mask>
                    <path
                      fill="#171C26"
                      fill-rule="nonzero"
                      d="M14.142 33.618h3.865v-4.296h-3.865v4.296zm4.547 1.335h-5.23c-.18 0-.354-.07-.482-.194-.128-.126-.2-.296-.2-.474v-5.631c0-.178.072-.348.2-.474.128-.125.302-.195.482-.194h5.23c.377 0 .683.3.683.668v5.631c0 .178-.073.348-.201.474-.128.125-.302.195-.482.194zm4.623-1.335h3.864v-4.296h-3.865v4.296h.001zm4.546 1.335h-5.23c-.18 0-.354-.07-.482-.194-.128-.126-.2-.296-.2-.474v-5.631c0-.369.306-.668.682-.668h5.23c.377 0 .683.3.683.668v5.631c0 .178-.073.348-.201.474-.128.125-.302.195-.482.194zM14.142 45.592h3.865v-4.296h-3.865v4.296zm4.547 1.336h-5.23c-.18 0-.354-.07-.482-.194-.128-.126-.2-.296-.2-.474v-5.632c0-.178.073-.348.2-.473.129-.125.302-.195.482-.194h5.23c.18 0 .353.069.482.194.128.125.2.295.2.473v5.632c0 .178-.072.348-.2.474-.128.125-.302.195-.482.194zM23.312 55h3.864V41.296h-3.865V55h.001zm4.546 1.335h-5.23c-.18 0-.354-.07-.482-.194-.128-.126-.2-.296-.2-.474V40.628c0-.178.073-.348.2-.473.129-.125.302-.195.482-.194h5.23c.18 0 .353.069.481.194.129.125.2.295.202.473v15.04c-.001.178-.073.348-.202.473-.128.125-.3.195-.481.194z"
                      mask="url(#h7s24hpjyd)"
                    />
                    <mask id="snvxr1lvsf" fill="#fff">
                      <use xlink:href="#qr7asio6te" />
                    </mask>
                    <path
                      fill="#000"
                      fill-rule="nonzero"
                      d="M63.154 23.865c-.165 0-.324-.058-.45-.164l-13.746-11.72-13.745 11.72c-.285.243-.715.214-.964-.064-.119-.133-.18-.308-.167-.485.013-.177.097-.341.233-.457L48.51 10.592c.258-.22.64-.22.898 0l14.196 12.103c.284.242.312.664.065.942-.13.145-.318.228-.515.228"
                      mask="url(#snvxr1lvsf)"
                    />
                    <g>
                      <mask id="f0ecizbywh" fill="#fff">
                        <use xlink:href="#oqmu1asy1g" />
                      </mask>
                      <path
                        fill="#C1C8D6"
                        fill-rule="nonzero"
                        d="M51.698 19.557c0 1.478-1.226 2.676-2.74 2.676-1.511 0-2.737-1.198-2.737-2.676 0-1.478 1.226-2.677 2.738-2.677s2.739 1.199 2.739 2.677"
                        mask="url(#f0ecizbywh)"
                      />
                    </g>
                    <g>
                      <mask id="luibufdsoj" fill="#fff">
                        <use xlink:href="#3fojk8bzhi" />
                      </mask>
                      <path
                        fill="#171C26"
                        fill-rule="nonzero"
                        d="M42.532 33.618h3.865v-4.296h-3.865v4.296zm4.547 1.335h-5.23c-.18 0-.354-.07-.482-.194-.128-.126-.2-.296-.2-.474v-5.631c0-.178.072-.348.2-.474.128-.125.301-.195.482-.194h5.23c.377 0 .683.3.683.668v5.631c0 .178-.073.349-.2.474-.13.125-.303.195-.483.194zm4.623-1.335h3.863v-4.296h-3.863v4.296zm4.546 1.335h-5.23c-.18 0-.353-.07-.481-.194-.128-.126-.2-.296-.2-.474v-5.631c0-.369.305-.668.682-.668h5.23c.377 0 .683.3.683.668v5.631c0 .178-.073.349-.2.474-.13.125-.303.195-.483.194h-.001zM42.532 45.592h3.865v-4.296h-3.865v4.296zm4.547 1.336h-5.23c-.18 0-.354-.07-.482-.194-.128-.126-.2-.296-.2-.474v-5.632c0-.178.072-.348.2-.473s.302-.195.482-.194h5.23c.18-.001.353.069.482.194.128.125.2.295.201.473v5.632c0 .178-.073.349-.2.474-.13.125-.303.195-.483.194zM51.702 55h3.863V41.296h-3.863V55zm4.546 1.335h-5.23c-.18 0-.353-.07-.481-.194-.128-.126-.2-.296-.2-.474V40.628c0-.178.072-.348.2-.473s.302-.195.482-.194h5.23c.18-.001.353.069.482.194.128.125.2.295.201.473v15.04c0 .178-.073.348-.201.473-.129.125-.302.195-.482.194h-.001zM9.616 55h21.907V21.32L20.57 11.98 9.616 21.32V55zm22.59 1.335H8.934c-.18 0-.354-.069-.482-.194s-.2-.295-.201-.473V21.016c0-.192.084-.376.233-.503l11.636-9.921c.258-.22.64-.22.898 0l11.636 9.921c.15.127.235.31.235.503v34.652c-.001.178-.073.348-.201.473s-.302.195-.482.194z"
                        mask="url(#luibufdsoj)"
                      />
                    </g>
                    <g>
                      <mask id="l8bfkcidpl" fill="#fff">
                        <use xlink:href="#qs9a4pnylk" />
                      </mask>
                      <path
                        fill="#171C26"
                        fill-rule="nonzero"
                        d="M38.006 55h21.907V21.32l-10.954-9.34-10.953 9.34V55zm22.59 1.335H37.323c-.18.001-.354-.069-.482-.194s-.2-.295-.202-.473V21.016c0-.192.087-.376.235-.503l11.636-9.921c.258-.22.64-.22.898 0l11.636 9.921c.15.127.234.31.234.503v34.652c0 .178-.073.348-.2.473-.129.125-.302.195-.482.194z"
                        mask="url(#l8bfkcidpl)"
                      />
                    </g>
                  </g>
                </g>
              </svg>

              <p>
                300 Renaissance Ctr<br />
                Detroit, MI 48243<br />
                juan.torres@osvi-industries.com
              </p>
            </div>
          </div>
        </div>
        <p class="email-note">
          Invoices and Billing notifications will be sent to all <b>Account Admins</b> and to the <b>Account Email Address</b>.
          <InfoHoverIcon
            style="display: inline-block; vertical-align: sub;"
            msg="Changing the 'Account Business Email' can be done in the 'Account Details' section on the 'Settings' page."
          />
        </p>
      </section>
      <section class="divider">
        <header class="flex spaced-between align-center">
          <h2>{{ $t("message.invoices") }}</h2>
          <button
            class="bg-white color-blue bold"
            style="font-size: 15px"
            @click="
              showBillingModal = true;
              $store.commit('openBillingModal');
              $store.commit('toggleModal');
            "
          >
            {{ $t("message.billingHistory") }}
          </button>
        </header>
        <div class="border sub-section" style="padding: 3% 5%">
          <div class="flex spaced-between align-center" style="height: 24px; margin-bottom: 4px;">
            <h3>{{ $t("message.yourLastInvoice") }}</h3>
            <button class="bg-white color-blue bold" style="padding: 0;">
              <svg id="gfs:icon1/download" style="width: 24px; fill: var(--blue)" viewBox="0 0 24 24">
                <path d="M20.53 19.85l.1.1v1.55l-.1.1h-17l-.1-.1v-1.55l.1-.1h17zM12.8 4.4l.1.1v10.22l3.1-3.3.06-.06a1.57 1.57 0 012.22.06v.14l-6.18 6.6h-.15l-6.18-6.6v-.14l.07-.06c.63-.6 1.62-.57 2.22.06l3.1 3.3V4.5l.1-.1h1.54z"></path>
              </svg>
            </button>
          </div>
          <div class="invoice-details">
            <p class="date small-text">{{ $t("message.invoiceDate") }}</p>
            <b>10/15/2020</b>
            <p class="amount small-text">{{ $t("message.amount") }}</p>
            <b>
              {{
                $i18n.locale === "es"
                  ? "$499"
                  : $i18n.locale === "fr-ca"
                  ? "47,97 $"
                  : "$47.97"
              }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                class="green-check"
              >
                <g fill="#1DD8AA">
                  <circle cx="28" cy="28" r="28" />
                  <path
                    fill="#fff"
                    d="M22.815 37.691a1.208 1.208 0 0 1-.06-.055l-9.389-9.457a1.25 1.25 0 1 1 1.768-1.768l8.529 8.58 17.209-16.64a1.25 1.25 0 0 1 1.736 1.798l-18.1 17.502a1.25 1.25 0 0 1-1.693.04z"
                  />
                </g>
              </svg>
            </b>
          </div>
        </div>
      </section>
    </main>
    <GuidedTourWaypoint :id="17" target="subs-header" :xOffset="200" :yOffset="70" :initRotation="180" forceYReverse />
  </div>
</template>

<script>
import Modal from "../../components/demo/Modal.vue";
import MiniDataTable from "../../components/demo/MiniDataTable.vue";
import InfoHoverIcon from "../../components/demo/InfoHoverIcon.vue";
import GuidedTourWaypoint from "../../components/demo/GuidedTourWaypoint.vue";

export default {
  components: {
    Modal,
    MiniDataTable,
    InfoHoverIcon,
    GuidedTourWaypoint
},
  computed: {
    modalHeading() {
      return this.$store.state.showBillingModal
        ? this.$t("message.billingHistory")
        : this.$t("message.selectPaymentType");
    },
    inEssentialsMode() {
      return this.$store.state.inEssentialsMode;
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin: 0 auto;
  text-align: center;
  margin: 24px;
}
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1040px;
  margin: 0 auto 32px auto;
  padding: 0 5%;
  font-family: ProximaNova;
  @media screen and (max-width: 1112px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
  section {
    padding-bottom: 16px;
    &:first-child {
      border-right: 1px solid var(--pale-grey);
      border-bottom: 1px solid var(--pale-grey);
      @media screen and (max-width: 1112px) {
        border-right: unset;
      }
    }
    &:nth-child(2) {
      border-bottom: 1px solid var(--pale-grey);
    }
    &:nth-child(3) {
      border-right: 1px solid var(--pale-grey);
      @media screen and (max-width: 1112px) {
        border-right: unset;
        border-bottom: 1px solid var(--pale-grey);
      }
    }
    #estimate-container {
      display: grid;
      height: 90%;
      box-sizing: border-box;
      grid-template-rows: 1fr auto;
      padding: 46px 0 0 0;
    }
  }
  //UTILITIES
  .grow-1 {
    flex-grow: 1;
  }

  .bold {
    font-weight: bold;
  }

  .left-align {
    text-align: left;
    text-wrap: nowrap;
  }

  .bg-white {
    background: #fff;
  }

  .color-blue {
    color: var(--blue);
  }

  .align-start {
    align-items: flex-start;
    p {
      margin: 0;
    }
  }
  .align-center {
    align-items: center;
  }

  .column {
    flex-direction: column;
  }

  .height-full {
    height: 100%;
  }
  flex {
    display: flex;
  }

  .highlight {
    background: #eaf1fb;
    button {
      background: var(--blue);
      color: #fff;
    }
  }

  .spaced-between {
    justify-content: space-between;
  }

  .small-text,
  .medium-text {
    color: var(--dark-grey);
  }
  .small-text {
    font-size: 12px;
  }
  .medium-text {
    font-size: 15px;
  }
  .height-80 {
    height: 80%;
  }

  //END UTILITIES

  header {
    padding: 0 5%;
  }
  h2,
  h3 {
    font-size: 16px;
  }
  h2 {
    font-weight: 700;
    text-transform: uppercase;
  }

  .border {
    border: 1px solid var(--light-grey);
    border-radius: 0.25rem;
  }
  .sub-section {
    padding: 10px 5%;
    margin: 2% 5%;

    p {
      margin: 0;
    }

    .logo {
      width: 2.5rem;
      height: 2.5rem;
    }
    &-content {
      margin: 21px 0;
      .content-text {
        margin: 0 0 0 2%;
      }
      h3,
      .content-text h3 {
        margin: 0;
      }
    }

    .circle {
      margin: 0;
      padding: 0;

      border-radius: 50%;
      padding: 5px;
      width: 2.5rem;
      height: 2.5rem;
    }

    .vehicle-count {
      display: flex;
      width: 15%;
      margin: 0;
      svg {
        height: 20px;
        width: 20px;
        margin-right: 2px;
      }
    }
  }

  button {
    font-family: inherit;
    height: fit-content;
    padding: 10px;
    border-radius: 0.25rem;
    border: 0;
    // max-width: 35%;
  }

  .divider {
    header {
      padding: 25px 5% 5px 5%;
    }
  }
  .invoice-details {
    b {
      margin: 0;
      font-size: 13px;
      position: relative;
    }
    p {
      margin-top: 4px;
    }
    .green-check {
      position: absolute;
      width: 16px;
      height: 12px;
      top: 4px;
      margin-left: 2px;
    }
  }

  .billing-details {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 100px 1fr;
    gap: 16px;
    margin-bottom: 16px;
    &.payment-info {
      grid-template-columns: 100px 1fr;
      color: var(--blue);
      button {
        background-color: #edf9ff;
        color: #05b5e4;
        font-weight: bold;
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          height: 30px;
          fill: #05b5e4;
        }
      }
    }
    p {
      justify-self: start;
      color: #76777a;
    }
    img {
      align-self: center;
    }
  }

  span {
    padding: 1px;
  }

  section {
    max-height: 700px;
    display: flex;
    flex-direction: column;
  }
}

// modal
div section.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 70vh;

  p {
    padding: 0;
    &.or {
      margin: 20px 0;
    }
  }

  button {
    height: 65px;
    font-weight: bold;
    width: 38%;
    font-family: ProximaNova;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    .icon {
      height: 35px;
      margin: 0 3%;
    }
    &.primary {
      background-color: var(--blue);
      border: 1px solid var(--blue);
      color: white;
      .icon {
        fill: #fff;
      }
    }
    &.secondary {
      background-color: #fff;
      border: 1px solid var(--pale-grey);
      color: var(--blue);
      .icon {
        fill: var(--blue);
      }
    }

    @media screen and (max-width: 1112px) {
      width: 80%;
    }
  }
}
@media screen and (max-width: 1112px) {
  main {
    padding: 0 10px;
  }
  #estimate-container {
    height: 200px;
    gap: 32px;
    padding-top: 0;
  }

  #cancel-btn {
    width: 100%;
    max-width: unset;
  }
  #learn-more-container {
    span {
      width: 70px;
    }
  }
  header {
    padding: unset;
  }
  section {
    width: 98%;
  }
  main .sub-section .circle {
    min-width: 2.5rem;
  }
  #trial-heading {
    font-size: 15px;
  }
}

// buttons
button.action-button {
  height: 30px;
  width: fit-content;
  min-width: 120px;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  background-color: var(--white);
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}

.plan-grid {
  display: grid;
  flex-grow: 1;
  padding-left: 16px;
  grid-template-rows: 21px 21px 23px;
  .num-vehicles {
    display: flex;
    align-items: center;
    gap: 3px;
    svg {
      width: 15px;
    }
    b {
      color: var(--blue);
    }
  }
}
.price-grid {
  display: grid;
  grid-template-rows: 25px 16px 16px;
  text-align: center;
  font-size: 12px;
  b {
    font-size: 16px;
  }
  span {
    color: #76777a;
  }
}
.coaching-icon-container {
  width: 37.5px !important;
  box-sizing: border-box;
  svg {
    width: 100%;
  }
}

#estimate-container .value-table {
  max-width: max-content;
  display: grid;
  align-items: center;
  grid-template-rows: 21px 21px 8px 21px;
  grid-template-columns: 160px 1fr;
  gap: 8px;
  .line {
    width: 100%;
    border-top: 1px solid #e8e8eb;
    grid-column: 1 / -1;
  }
}

.email-note {
  color: #76777a;
  margin: 2% 5%;
}
</style>
